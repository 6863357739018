const envConfig = {
  "environment": "production",
  "mainDomain": "aktionsfinder.prod.shared-k8s.postag.intern",
  "apiUrl": "https://api.post.at/aktionsfinder/core-api",
  "cdnUrl": "https://afassetsproduction-hbcechbcaea6gdds.a03.azurefd.net/202501091502/",
  "azureApiSubscriptionKey": "",
  "apiClientToken": "YWZ3ZWJwd2E6OTNtMXBpZTY0bnN6NnVsZnRkeWQ0MjhuNjl6N296OHRwbXQwM3VkZQ==",
  "apiClientTokenMobile": "bW9iaWxlOmNhYmIzYjNjOTJlMzdkMDliYjc0MDM4MThhMmI1MGU4ZDk0M2YzNTU=",
  "recaptchaV3SiteKey": "6LcQXpAqAAAAAGVG7F6kyWqRzrhj0fi9Vnx3XZtS",
  "newsletterSignupUrl": "https://news.post.at/formview/?p=z8baed3148e3239d09b4d652988a3050d",
  "matomoUrl": "https://matomo.post.at/js/container_PagWSBI8.js",
  "googleMapsApiKey": "AIzaSyDv-OJVHjHIGkMLS6So-o-VSjdL2nfFRyg",
  "azureApplicationInsightsConnectionString": "InstrumentationKey=12f2f0d4-0f88-4c5c-967d-e72cbeb56cd0;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=fb894def-cf0c-4305-a7bd-96b2337caf21"
}; console.log(envConfig); export default envConfig;